<template>
  <b-card title="Clients">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Client</span>
        </template>

        <AddClient />
      </b-tab>
      <b-tab @click="$router.push('/all-client')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> All Clients</span>
        </template>
        <router-view />
        <!-- <AllClients></AllClients> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import AllClients from './AllClients.vue'
import AddClient from './AddClient.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    AllClients,
    AddClient,
  },
  data() {
    return {

    }
  },
}
</script>
